import React from 'react'
import './Customers.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function Customers() {
  return (
    <div className="Customers">
       <Navbar />
      <Banner />
      <div className="Customers-container">
        <div className="customers-left">
          <Sidebar />
        </div>
        <div className="customers-right">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
