import React from 'react'
import './Quality.scss'
import leftImg from '../../../img/quality_gallery/quality-left-img.jpg'
import rightImg1 from '../../../img/quality_gallery/pdf-bg.png'
import rightImg2 from '../../../img/quality_gallery/urs-logo.jpg'
import { Link } from 'react-router-dom'


export default function Quality() {
    return (
        <div className="Quality">
            <div className="quality-container">
                <div className="quality-right">
                    <h2>Quality</h2>
                    <span>Trans Tech Projects is committed to a Quality Management System to provide cost-effective, timely and customer focused solutions.</span>
                    <span>We practice open & participative work environment for continual improvement in quality of our processes, products, services & quality management systems to achieve customer satisfaction.</span>
                    <span>Our Quality Management System is certified as per ISO 9001 : 2015.</span>

                    <div className="quality-right-img">
                        <img src={rightImg2} alt="" />
                        <a href="/TTPLISOCERTUPTO17-JUL-2020.pdf" target='_blank'>
                            <img src={rightImg1} alt="" />
                        </a>
                    </div>
                </div>
                <div className="quality-left">
                    <Link to="/showcase">
                        <span>SHOWCASE</span>
                    </Link>
                    <img src={leftImg} alt="" />
                </div>
            </div>
        </div>
    )
}
