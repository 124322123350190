import React from 'react'
import './SoftTools.scss'
import { Link } from 'react-router-dom'

export default function SoftTools() {
    return (
        <div className="SoftTools">
            <div className='SoftTools-title'>
                <h2>Software Tools</h2>
                <div className="link">
                    <Link to="/showcase">
                        <span>SHOWCASE</span>
                    </Link>
                </div>
            </div>
            <div className="SoftTools-container">
                <table>
                    <thead>
                        <tr>
                            <th>Discipline</th>
                            <th>Activity</th>
                            <th>Software</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>Process</td>
                            <td>P&ID</td>
                            <td>AutoCAD, Cadworx P&ID</td>
                        </tr>
                        <tr>
                            <td rowSpan={'2'}>Mechanical equipment</td>
                            <td>3D design</td>
                            <td>Cadworx, Inventor, Solidworks</td>
                        </tr>
                        <tr>
                            <td>2D design</td>
                            <td>AutoCAD</td>
                        </tr>
                        <tr>
                            <td rowSpan={'2'}>Piping</td>
                            <td>3D design</td>
                            <td>Cadworx, Inventor, Solidworks</td>
                        </tr>
                        <tr>
                            <td>2D design</td>
                            <td>AutoCAD</td>
                        </tr>
                        <tr>
                            <td rowSpan={'3'}>Structural</td>
                            <td>Design & Analysis</td>
                            <td>StaadPro*</td>
                        </tr>
                        <tr>
                            <td>2D design</td>
                            <td>AutoCAD, Cadworx P&ID</td>
                        </tr>
                        <tr>
                            <td>2D design</td>
                            <td>AutoCAD</td>
                        </tr>
                        <tr>
                            <td rowSpan={'3'}>Instrumentation & Electrical</td>
                            <td>Databases and schematics</td>
                            <td>AutoCad Electrical</td>
                        </tr>
                        <tr>
                            <td>Cable routes</td>
                            <td>AutoCAD</td>
                        </tr>
                        <tr>
                            <td>Layouts</td>
                            <td>AutoCAD</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}
