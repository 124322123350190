import React from 'react';
import './ScrubbingProduct.scss';
import p1 from '../../../img/scrubbing_product-gallery/TT_Scrubber_3D.png';
import p2 from '../../../img/scrubbing_product-gallery/TT_Srubber_GA.jpg';
import { Link } from 'react-router-dom';

export default function ScrubbingProduct() {
  return (
    <div className="ScrubbingProduct">
        <div className="scrubbing-product-container">
            <div className="scrubbing-product-left">
        <h2>Scrubbing System</h2>
                <span>Trans Tech Projects Pvt. Ltd. offers Venturi and Packed Tower Absorbers for removal of gaseous pollutants such as Cl2, ClO2, HCl, SO2, NH3, NOx, H2S, mercaptans, amines. The system can be either combination of venturi and packed tower or can be individual absorbers</span>
                <span>In venturi scrubber, the gaseous stream gets sucked using venturi from the source where it comes in contact with scrubbing liquid. Any particulate matters gets removed at this stage. A high pressure pump is required to generate the suction for the gaseous stream.</span>
                <span>In packed tower, the gaseous stream flows upward through a packed bed while the scrubbing liquid flows downward by gravity over the packing. Packed bed consists of random or high performance packing which provides a close gas-liquid contact.</span>
                <span>The internal components of the tower consist of a packing support plate, a packed bed, a liquid distributor and a mist eliminator. Packed tower absorbers operate at relatively low pressure drops and handle high circulation rates of scrubbing liquid. The tower can be designed for once through operation or to recycle the liquid, depending on the process requirement.</span>
                <span>Venturi & P acked tower absorbers are available in alloy steel, PP / FRP and rubber lined mild steel. The tower internals can be made from polypropylene or any other materials including metals depending on the process conditions.</span>
                <span>A team of expert chemical engineers is available for process design, detail engineering, installation & commissioning.</span>
            </div>
            <div className="scrubbing-product-right">
            <Link to="/showcase/showscrubbingsystem">
            <span>SHOWCASE</span>
          </Link>
                <img src={p1} alt="" />
                <img src={p2} alt="" />
            </div>
        </div>
    </div>
  )
}
