import React from 'react'
import './Testimonial.scss'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../Navbar/Navbar'
import Banner from '../Banner/Banner'
import Footer from '../Footer/Footer'

export default function Testimonial() {

    const content = [
        {
            text: 'I would like to take this opportunity to express my appreciation of working with your team. You are all very professional and we like the quality of your drawings. Thank you!',
            latin: '— Project Manager, Control System Integrator in North America'
        },
        {
            text: 'Thank you for staying late to for programming support. You are doing a great job!',
            latin: '— Senior Programmer, Control System Integrator in North America'
        },
        {
            text: 'Thanks you for quality work. We have shipped the skid last week.',
            latin: '— Project Manager, Oil & Gas Skid Manufacturer in North America'
        },
        {
            text: 'TransTech Team, Thank you for your support for past few months. This was prestigious project for us and your services helped us to deliver it on time.',
            latin: '— Project Manager, Water Treatment OEM in North America'
        },
        {
            text: 'I wanted to let you know that you all are doing a wonderful job. Thanks for all your support.',
            latin: '— Engineer, consulting engineering company in north america'
        },
        {
            text: "Thanks for getting these P&ID's done so quickly, and as always for the high quality of your work.",
            latin: '— Project Manager, Consulting Engineering Company in North America'
        },
    ]


    return (
        <div className="Testimonial">
            <Navbar />
            <Banner />
            <div className="Testimonial-container">
                <div className="Testimonial-left">
                    <Sidebar />
                </div>
                <div className="Testimonial-right">
                    <h2>What customers are saying about us</h2>
                    <div className="Testimonial-right-container">
                        {content.map((item, i) => {
                            return (
                                <div className="Testimonial-content" key={i}>
                                    <span className='normal'>{item.text}</span>
                                    <span className="latin">{item.latin}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
