import React from 'react'

import './Footer.scss'


export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  
  
  
  return (

    <div className="Footer">

      <span>© Copyright   {year} All Rights Reserved. Powered by :</span>
      <a href='/' className="company-name"> Trans Tech Projects Pvt. Ltd.</a>
  

    </div>
  )
}