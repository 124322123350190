import React from 'react';
// import './Navbar.css';
import './Navbar.scss';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../img/new/navbar-light-logo.png';
import darklogo from '../../img/new/TTPL-logo.png';
import menu from '../../img/menu/menu.png';
import darkmenu from '../../img/dark/1x/Asset 2.png';
import darkicon from '../../img/dark/1x/darkicon.png';
import { useDispatch, useSelector } from 'react-redux';
import { modes } from '../../redux/themeSlice';
import { navbarShow } from '../../redux/navSlice';

export default function Navbar() {
    const location = useLocation();
    const darkmode = useSelector(state => state.theme.dark_mode);
    const show = useSelector(state => state.nav.show);
    const dispatch = useDispatch();
    const handleShowMenu = (e) => {
        e.preventDefault();
        if (show) {
            dispatch(navbarShow(false))
        } else {
            dispatch(navbarShow(true))
        }
    }

    const handledarkmode = () => {
        if (darkmode) {
            dispatch(modes(false))
        }
        else {
            dispatch(modes(true))
        }
    }

    return (
        <div className="Navbar">
            <div className="container">

                <img src={darkmode ? darkmenu : menu} onClick={handleShowMenu} alt="" className="menu" />
                <div onClick={handleShowMenu} className={`navbar-links ${show ? 'navbar-links-show' : 'navbar-links-hide'}`}>
                    <ul className={`navbar-ul `} >
                        <li>
                            <img className="darkmodeimg" src={darkicon} onClick={handledarkmode} alt="" />
                            <Link className={`${location.pathname.split('/')[1] === "" ? "a-active" : 'a'}`} to="/">HOME</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "about" ? "a-active" : 'a'}`} to="/about">ABOUT US</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "services" ? "a-active" : 'a'}`} to="/services">SERVICES</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "products" ? "a-active" : 'a'}`} to="/products">PRODUCTS</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "customers" ? "a-active" : 'a'}`} to="/customers">CUSTOMERS</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "offshore" ? "a-active" : 'a'}`} to="/offshore">OFFSHORE MODEL</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "careers" ? "a-active" : 'a'}`} to="/careers">CAREERS</Link>
                        </li>
                        <li>
                            <Link className={`${location.pathname.split('/')[1] === "contact" ? "a-active" : 'a'}`} to="/contact">CONTACT US</Link>
                        </li>
                    </ul>
                </div>
                <Link to="/">
                    <img src={darkmode ? darklogo : logo} alt="transtech" className="logo" />
                </Link>
            </div>
        </div>
    )
}
