import React from 'react'
import './Equipment.scss'
import ShowcaseComponent from '../../ShowcaseComponent/ShowcaseComponent'

export default function Equipment() {
  return (
    <div className="Equipment">
      <div className="equipment-container">
        <div className="left">
          <h2>Equipment & System Manufacturers</h2>
          <span>Equipment & Package Unit manufacturers who are manufacturing Solvent Recovery (Distillation & Evaporation) Systems, Scrubbing Systems, Adsorption Systems, Thermic Fluid Heaters, Thermal Oxidizers, Boilers, Material Handling Systems, Packaging Machine Suppliers can avail help from us to have better control on manpower costs & quick turnaround.</span>

          <h3>How can we help?</h3>
          <ul>
            <li>Preparation of Drawings such as P & IDs, Equipment Fabrication Drawings, Skid Layouts Isometric Drawings in 2D & 3D</li>
            <li>Preparation of Panel Layouts, Wiring Drawings, Cable Schedule</li>
            <li>PLC / HMI / SCADA programming & associated documentation</li>
            <li>Preparation of Training Manual, Operation & Maintenance Manual</li>
            <li>Assistance in Proposal Making</li>
          </ul>
        </div>
        <div className='right'>
            <ShowcaseComponent  />
        </div>
      </div>
    </div>
  )
}
