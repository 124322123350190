import React from 'react'
import './SystemIntegrators.scss'
import ShowcaseComponent from '../../ShowcaseComponent/ShowcaseComponent'

export default function SystemIntegrators() {
  return (
    <div className="SystemIntegrators">

      <div className="system-in-container">
        <div className='left'>

          <h2>System Integrators</h2>
          <span>System Integrators (SI) & Electrical Contractors who are providing systems for Water / Waste Water Treatment Plants, Food & Beverages, Material Handling, Chemical & Pharmaceutical Plants, Building Automation projects can avail help from us to reduce their costs & quick turnaround for submittals & programming.</span>

          <h3>How can we help?</h3>
          <ul>
            <li>Submittals consisting of pdf cutsheets for Panel Devices, Field Instruments</li>
            <li>MCC & Control Panel Layouts, Wiring Schematics, Conduit Schedule, Loop Drawings</li>
            <li>SCADA / OIT Screen Generation, Animation, Report Generation, Historical Data Archiving</li>
            <li>PLC Programming & Documentation</li>
            <li>Preparation of Training Manuals, Operation & Maintenance Manuals</li>
            <li>Assistance in Proposal Making</li>
          </ul>
        </div>
        <div className='right'>
          <ShowcaseComponent />
        </div>
      </div>
    </div>
  )
}
