import React, { useEffect, useState } from 'react'
import './Banner.scss'
import modeling3d from '../../img/1x/Asset-5.png'
import automationcontrol from '../../img/1x/Asset-1.png'
import detailengineering from '../../img/1x/Asset-3.png'
import modulerskilleddesign from '../../img/1x/Asset-4.png'
import scrubingsystem from '../../img/1x/Asset-6.png'
import softwaredevelopment from '../../img/1x/Asset-2.png'
import { Link, useLocation } from 'react-router-dom'
import darkicon1auto from '../../img/dark/1x/Asset 34.png';
import darkicon1software from '../../img/dark/1x/Asset 33.png';
import darkicon1detail from '../../img/dark/1x/Asset 29.png';
import darkicon1modular from '../../img/dark/1x/Asset 32.png';
import darkicon1modeling from '../../img/dark/1x/Asset 31.png';
import darkicon1scrubbing from '../../img/dark/1x/Asset 30.png';
import { useSelector } from 'react-redux';
import darklogo from '../../img/new/TTPL-logo.png';

export default function Banner() {
    const location = useLocation();
    const [heading, setHeading] = useState('');
    const darkmode = useSelector(state => state.theme.dark_mode);

    const data1 = [
        {
            img: !darkmode ? automationcontrol : darkicon1auto,
            title: 'Industrial \nAutomation',
            link: '/services'
        },
        {
            img: !darkmode ? softwaredevelopment : darkicon1software,
            title: 'MES & \nSoftware',
            link: '/services/software'
        },
        {
            img: !darkmode ? detailengineering : darkicon1detail,
            title: 'Detail \nEngineering',
            link: '/services/detailengineering'
        },
    ]
    const data2 = [
        {
            img: !darkmode ? modulerskilleddesign : darkicon1modular,
            title: 'Modular Skids \nDesign',
            link: '/services/modular'
        },
        {
            img: !darkmode ? modeling3d : darkicon1modeling,
            title: '3D Modeling & \nDrafting',
            link: '/services/modeling'
        },
        {
            img: !darkmode ? scrubingsystem : darkicon1scrubbing,
            title: 'Scrubbing \nSystems',
            link: '/products'
        },
    ]

    useEffect(() => {
        if (location.pathname === '/') {
            setHeading('AUTOMATION \nENGINEERING \nDRAFTING')
        }
        else if (location.pathname.split('/')[1] === 'showcase') {
            setHeading('AUTOMATION \nENGINEERING \nDRAFTING')
        }
        // else if (location.pathname === '/showcase/showmodeling') { 
        //     setHeading('3D MODELING \n& PIPING')
        // }
        // else if (location.pathname === '/showcase/showdetailengineering') { 
        //     setHeading('DETAIL \nENGINEERING')
        // }
        // else if (location.pathname === '/showcase/showcoustomdesign') { 
        //     setHeading('CUSTOM \nDESIGN')
        // }
        // else if (location.pathname === '/showcase/showscrubbingsystem') { 
        //     setHeading('SCRUBBING \nSYSTEM')
        // }
        // else if (location.pathname === '/showcase/showcolumninternals') { 
        //     setHeading('COLUMN \nINTERNALS')
        // }
        else if (location.pathname === '/services') {
            setHeading('INDUSTRIAL \nAUTOMATION')

        }
        else if (location.pathname === '/testimonial') {
            setHeading('TESTIMONIALS')

        }
        else if (location.pathname === '/services/software') {
            setHeading('MES & \nSOFTWARE')

        }

        else if (location.pathname === '/services/detailengineering') {
            setHeading('DETAIL \nENGINEERING')

        }
        else if (location.pathname === '/services/engprofile') {
            setHeading('DETAIL \nENGINEERING')

        }

        else if (location.pathname === '/services/modular') {
            setHeading('MODULAR \nSKIDS DESIGN')

        }

        else if (location.pathname === '/services/modeling') {
            setHeading('3D MODELING \n& DRAFTING')

        }
        else if (location.pathname === '/services/profile3d') {
            setHeading('3D MODELING \n& DRAFTING')

        }

        else if (location.pathname === '/offshore') {
            setHeading('OFFSHORE \nMODEL')

        }
        else if (location.pathname === '/careers') {

            setHeading('CAREERS')
        }
        else if (location.pathname === '/contact') {
            setHeading('CONTACT US')

        }
        else if (location.pathname.split('/')[1] === 'about') {
            setHeading('ABOUT US')

        }
        else if (location.pathname.split('/')[1] === 'success') {
            setHeading('SUCCESS \nSTORIES')

        }
        else if (location.pathname === '/products') {
            setHeading('AUTOMATION \nENGINEERING \nDRAFTING')

        }
        else if (location.pathname === '/products/scrubbingprofile') {
            setHeading('SCRUBBING \nSYSTEM')

        }
        else if (location.pathname.split('/')[2] === 'columninternals') {
            setHeading('COLUMN \nINTERNALS')

        }
        else if (location.pathname === '/products/columnprofile') {
            setHeading('COLUMN \nINTERNALS')

        }
        else if (location.pathname === '/products/scrubbingprofile') {
            setHeading('COLUMN \nINTERNALS')

        }
        else if (location.pathname.split('/')[2] === 'controlpanels') {
            setHeading('CONTROL \nPANELS')

        }
        else if (location.pathname.split('/')[2] === 'controlpanelprofile') {
            setHeading('CONTROL \nPANELS')

        }
        else if (location.pathname === '/customers') {
            setHeading('AUTOMATION \nENGINEERING \nDRAFTING')

        }
        else if (location.pathname === '/customers/systemintegrators') {
            setHeading('SYSTEM \nINTEGRATORS')

        }
        else if (location.pathname === '/customers/consultingcompanies') {
            setHeading('ENGINEERING & \nCONSULTING')

        }
        else if (location.pathname === '/customers/itfirms') {
            setHeading('IT FIRMS')

        }
        else if (location.pathname === '/webmail') {


        }

    }, [location.pathname])

    return (
        <div className="Banner">
            <div className="left">
                <em>{heading}</em>
            </div>
            <div className="mid">
                <div className="top">
                    {data1.map((box) => {
                        return <div className="box" key={box.title}>
                            <Link to={box.link}>
                                <img src={box.img} alt="" />
                                <span>{box.title}</span>
                            </Link>
                        </div>
                    })}
                </div>
                <div className="bottom">
                    {data2.map((box) => {
                        return <div className="box" key={box.title}>
                            <Link to={box.link}>
                                <img src={box.img} alt="" />
                                <span>{box.title}</span>
                            </Link>
                        </div>
                    })}
                </div>
            </div>
            <div className="right">
                <Link to={'/'}>
                    <img className="banner-logo" src={darklogo} alt="img" />
                </Link>
            </div>
        </div >
    )
}
