import React, { useState } from 'react';
import './Contact.scss'
import Banner from '../../components/Banner/Banner';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';

export default function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    emailAddress: '',
    phoneNo: '',
    faxNo: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    comment: '',
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const verifyRecaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LccK4MUAAAAAPktUD93LE7oEqNmS-FZ9bjj-4jf', { action: 'submit' }).then((token) => {
        console.log(token); // This is the reCAPTCHA token
        setRecaptchaToken({ recaptchaToken: token });

        // Send the token to your backend for verification
        // Example: You can use fetch or Axios to send a POST request
        // Replace 'YOUR_BACKEND_ENDPOINT' with your actual backend endpoint
        // Replace 'YOUR_RECAPTCHA_SECRET_KEY' with your actual reCAPTCHA secret key
        fetch('YOUR_BACKEND_ENDPOINT', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: token, secret: 'fz5PpYtgpa0ucfFJCoQdxE9k2cMh/zM9rRl96CNEBGmVzh2c1SkJWA==' }),
        })
          .then(response => response.json())
          .then(data => {
            // Handle verification response from the backend
            console.log(data);
            if (data.success) {
              // Verification successful
              alert('reCAPTCHA verification successful!');
            } else {
              // Verification failed
              alert('reCAPTCHA verification failed!');
            }
          })
          .catch(error => {
            console.error('Error:', error);
            alert('Error verifying reCAPTCHA. Please try again later.');
          });
      });
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    // const bodyToSend = {
    //   Email: "sk@vaspsolutions.com",
    //   Name: "Sumedh",
    //   Company: "vasp",
    //   Phone: "9730906426",
    //   Fax: "",
    //   Address: "Bavdhan",
    //   City: "Pune",
    //   Pin: "415605",
    //   State: "MH",
    //   Country: "IN",
    //   Comment: "Please ignore",
    // }
    event.preventDefault();

    if (formData.emailAddress == "" || formData.name == "" || formData.companyName == "" || formData.phoneNo == "" || formData.address == "" || formData.city == "" || formData.pincode == "" || formData.state == "" || formData.country == "" || formData.comment == "") {
      alert("All fileds are required to send mail")
      return;
    }

    const bodyToSend = {
      Email: formData.emailAddress,
      Name: formData.name,
      Company: formData.companyName,
      Phone: formData.phoneNo,
      Fax: formData.faxNo,
      Address: formData.address,
      City: formData.city,
      Pin: formData.pincode,
      State: formData.state,
      Country: formData.country,
      Comment: formData.comment,
    }


    try {
      const response = await axios.post('https://sendmailvasp.azurewebsites.net/api/SendMailForTTPL', bodyToSend, {
        params: {
          code: 'eCkVFSKvp2AprH4wvqxR6aKjagEUIuJalWK0DteDEhNZDICwaTG0JA=='
        }
      });
      alert("Message Sent Successfully");
    } catch (error) {
      console.error('Error:', error);
    }


    //   const responsePayload = await axios.post('https://sendmailvasp.azurewebsites.net/api/SendMailForTTPL', bodyToSend, {
    //     params: {
    //       code: "eCkVFSKvp2AprH4wvqxR6aKjagEUIuJalWK0DteDEhNZDICwaTG0JA=="
    //     }
    //   }).then(response => {
    //     // Handle successful response here, if needed
    //     alert("Message Sent Successfully");
    //   })
    //     .catch(error => {
    //       // Handle errors here
    //       console.error('Error:', error);
    //     });
    // }


    // var responsePayload = await axios.post('https://sendmailvasp.azurewebsites.net/api/SendMailForTTPL?code=eCkVFSKvp2AprH4wvqxR6aKjagEUIuJalWK0DteDEhNZDICwaTG0JA==', bodyToSend)
    //     .then(response => {
    //       // Handle successful response here, if needed
    //       alert("Message Sent Successfully");
    //     })
    //     .catch(error => {
    //       // Handle errors here
    //       console.error('Error:', error);
    //     });

    // };
  }
  return (
    <div className="Contact">
      <Navbar />
      <Banner />
      <div className="Contact-container">

        <div className="contact-left">
          <form className="contact-form" onSubmit={handleSubmit}>
            <span>Please fill up the following form for any query</span>
            <input type="text" className="contact-input" name="name" placeholder='Name' value={formData.name} onChange={handleChange} />
            <input type="text" className="contact-input" name="companyName" placeholder='Company Name' value={formData.companyName} onChange={handleChange} />
            <input type="email" className="contact-input" name="emailAddress" placeholder='Email Address' value={formData.emailAddress} onChange={handleChange} />
            <input type="text" className="contact-input" name="phoneNo" placeholder='Telephone Number' value={formData.phoneNo} onChange={handleChange} />
            <textarea name="address" placeholder='Address' value={formData.address} onChange={handleChange} />
            <div className="address-container">
              <div className="address-top">
                <input type="text" className="contact-input" name="city" placeholder='City' value={formData.city} onChange={handleChange} />
                <input type="text" className="contact-input" name="pincode" placeholder='Pin Code' value={formData.pincode} onChange={handleChange} />
              </div>
              <div className="address-buttom">

                <input type="text" className="contact-input" name="state" placeholder='State' value={formData.state} onChange={handleChange} />
                <input type="text" className="contact-input" name="country" placeholder='Country' value={formData.country} onChange={handleChange} />
              </div>
            </div>
            <textarea name="comment" placeholder='Comment' value={formData.comment} onChange={handleChange} />

            <button className='contact-form-submit'>Submit</button>
          </form>
        </div>
        <div className="contact-right">
          <h3>Trans Tech Projects Pvt. Ltd.</h3>
          <div className="right-address">
            <div className="item">
              <span className="span-bold">Address:</span>
              <span className="span-text">Plot No.17, Sr .No.13,</span>
              <span className="span-text">Near Bramha Chaitnya Society,</span>
              <span className="span-text">Vidnyan Nagar Road,</span>
              <span className="span-text">Bavdhan, Pune, Maharashtra - 411021, INDIA.</span>
            </div>
            <div className="item">
              <span className="span-bold">Telephone:</span>
              <span className="text-span">91-8983002534, 91-8983008534, 91-8446008660</span>
            </div>
            <div className="item">
              <span className="span-bold">E-mail: </span>
              <span className="span-text"> info@transtechprojects.com</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
