import { createSlice } from "@reduxjs/toolkit";

const navSlice  = createSlice({
    name: "nav",
    initialState: {
        show: false
    },
    reducers: {
        navbarShow: (state , action)=>{
            state.show = action.payload
        }
    }
})



export const { navbarShow } = navSlice.actions;
export default navSlice.reducer;