import React from 'react'
import './Services.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function Services() {
  return (
    <div className="Services">
       <Navbar />
      <Banner />
      <div className="Services-Container">
        <div className="left">
          <Sidebar />
        </div>
        <div className="right">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
