import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { Link, useLocation } from 'react-router-dom';
import ignition from '../../img/inductive-automoation.png';
// import year25_dark from '../../img/new/Transtech25yearemblem_Dark.png'
// import year25_light from '../../img/new/Transtech25yearemblem_Light.png'
import year25_dark from '../../img/new/new/Transtech25yearemblem_DarkMode2.png'
import year25_light from '../../img/new/new/Transtech25yearemblem_Lightmode2.png'
import { useSelector } from 'react-redux';

export default function Sidebar() {
    const dark_mode = useSelector(state => state.theme.dark_mode);
    const [links, setLinks] = useState([]);
    const [imgignition, setIngignition] = useState(false)
    const [title, setTitle] = useState('')
    const [profileLocation, setProfileLocation] = useState('');
    const [profile, setProfile] = useState(false)
    const location = useLocation();
    const SHOWCASE = [
        {
            name: 'Industrial Automation',
            link: '',
            id: 1
        },
        {
            name: '3D Modeling & Piping',
            link: 'showmodeling',
            id: 6
        },
        {
            name: 'Detail Engineering',
            link: 'showdetailengineering',
            id: 2
        },
        {
            name: 'Custom Design',
            link: 'showcoustomdesign',
            id: 3
        },
        {
            name: 'Scrubbing System',
            link: 'showscrubbingsystem',
            id: 4
        },
        {
            name: 'Column Internals',
            link: 'showcolumninternals',
            id: 5
        }
    ]
    const HOME = [
        {
            name: 'Control System Integrator',
            link: '/success',
            id: 1
        },
        {
            name: 'Modular Skid Manufacturer',
            link: '/success/modularskid',
            id: 2
        },
        {
            name: 'Engineering Firm',
            link: '/success/engineeringfirm',
            id: 3
        },
        {
            name: 'Chemical Manufacturing Plant',
            link: '/success/manufacturingplant',
            id: 4
        },
        {
            name: 'Scrubbing System',
            link: '/success/scrubbingsystem',
            id: 5
        },
        {
            name: 'Testimonials',
            link: '/testimonial',
            id: 6
        }
    ]

    const SERVICES = [
        {
            name: 'Industrial Automation',
            link: '',
            id: 1
        },
        {
            name: 'MES & Software',
            link: 'software',
            id: 2
        },
        {
            name: 'Detail Engineering',
            link: 'detailengineering',
            id: 3
        },
        {
            name: 'Modular Skids Design',
            link: 'modular',
            id: 4
        },
        {
            name: '3D Modeling & Drafting',
            link: 'modeling',
            id: 5
        },
    ]

    const ABOUT = [
        {
            name: 'About Company History',
            link: '',
            id: 1
        },
        {
            name: 'Software Tools',
            link: 'softwaretools',
            id: 2
        },
        {
            name: `Quality`,
            link: 'quality',
            id: 3
        },
    ]

    const PRODUCTS = [
        {
            name: 'Scrubbing System',
            link: '',
            id: 1
        },
        {
            name: 'Column Internals',
            link: 'columninternals',
            id: 2
        },
        {
            name: `Control Panels`,
            link: 'controlpanels',
            id: 3
        },
    ]

    const SUCCESS = [
        {
            name: 'Control System Integrator',
            link: '',
            id: 1
        },
        {
            name: 'Modular Skid Manufacturer',
            link: 'modularskid',
            id: 2
        },
        {
            name: `Engineering Firm`,
            link: 'engineeringfirm',
            id: 3
        },
        {
            name: `Chemical Manufacturing Plant`,
            link: 'manufacturingplant',
            id: 4
        },
        {
            name: `Scrubbing System`,
            link: 'scrubbingsystem',
            id: 5
        },
    ]
    const CUSTOMERS = [
        {
            name: 'Equipment & Systems Manufacturers',
            link: '',
            id: 1
        },
        {
            name: 'System Integrators',
            link: 'systemintegrators',
            id: 2
        },
        {
            name: `Engineering & Consulting Companies`,
            link: 'consultingcompanies',
            id: 3
        },
        {
            name: `IT Firms`,
            link: 'itfirms',
            id: 4
        },
    ]


    useEffect(() => {
        if (location.pathname === '/') {
            setTitle('SUCCESS STORIES')
            setLinks(HOME)
            setIngignition(false)
        }
        else if (location.pathname.split('/')[1] === 'showcase') {
            setTitle('SHOWCASE')
            setLinks(SHOWCASE)
            setIngignition(false)
        }
        else if (location.pathname.split('/')[1] === 'services') {
            setTitle('SERVICES')
            setLinks(SERVICES)
            setIngignition(true)

            if (location.pathname === '/services') {
                setProfile(true)
                setProfileLocation("autoprofile")

            }

            else if (location.pathname === '/services/detailengineering') {
                setProfile(true)
                setProfileLocation("engprofile")

            }

            else if (location.pathname === '/services/autoprofile') {
                setProfile(false)

            }
            else if (location.pathname === '/services/engprofile') {
                setProfile(false)

            }
            else if (location.pathname === '/services/profile3d') {
                setProfile(false)

            }

            else {
                setProfile(false)

            }
        }

        else if (location.pathname.split('/')[1] === 'about') {
            setTitle('ABOUT US')
            setLinks(ABOUT)
            setIngignition(false)
        }

        else if (location.pathname.split('/')[1] === 'products') {
            setTitle('PRODUCTS')
            setLinks(PRODUCTS)
            setIngignition(true)

            if (location.pathname === '/products') {
                setProfile(true)
                setProfileLocation("scrubbingprofile")
            }

            else if (location.pathname === '/products/columninternals') {
                setProfile(true)
                setProfileLocation("columnprofile")

            }
            else if (location.pathname === '/products/controlpanels') {
                setProfile(true)
                setProfileLocation("controlpanelprofile")

            }

            else {
                setProfile(false)

            }
        }

        else if (location.pathname.split('/')[1] === 'customers') {
            setTitle('CUSTOMERS')
            setLinks(CUSTOMERS)
            setIngignition(false)
        }

        else if (location.pathname.split('/')[1] === 'success') {
            setTitle('SUCCESS STORIES')
            setLinks(SUCCESS)
            setIngignition(false)
        }

        else {
            setIngignition(false)
        }




        // eslint-disable-next-line
    }, [location.pathname])
    return (
        <div className="Sidebar">
            {title && <h2>{title}</h2>}
            <div className="links">
                {links.map((link) => {
                    return <Link to={link.link} key={link.id}><span className="span">{link.name}</span></Link>
                })}

                {/* <a href="/"><span className="span">3D MODELING & PIPING</span></a>
                <a href="/"><span className="span">DETAIL ENGINEERING</span></a>
                <a href="/"><span className="span">CUSTOM DESIGN</span></a>
                <a href="/"><span className="span">SCRUBBING SYSTEM</span></a>
                <a href="/"><span className="span">COLUMN INTERNALS</span></a> */}
            </div>
            {profile && <div className="profile">
                <Link to={profileLocation}>View Experience Profile</Link>
            </div>}

            {/* {location.pathname.split('/')[1] !== 'showcase' &&  location.pathname !== '/' &&  location.pathname !== '/offshore' &&  location.pathname !== '/careers' &&  location.pathname !== '/testimonial' && <div className="showcase">
                <Link to="/showcase">
                    <span>SHOWCASE</span>
                </Link>
            </div>} */}
            <div className="year-25">
                <img className="year-25-img" src={dark_mode ? year25_dark: year25_light} alt="" />
            </div>
            {imgignition && <div className="ignition">
                <img className="ignition-img" src={ignition} alt="" />
            </div>}
            {location.pathname !== '/' && <div className="contact-details">
                <h3>ADDRESS</h3>
                <span>Plot No.17, Sr .No.13,
                    Near Bramha Chaitnya Society,
                    Vidnyan Nagar Road,
                    Bavdhan, Pune, Maharashtra - 411021, INDIA.</span>

                <h3>TELEPHONE</h3>
                <span>+ 91-8983002534,</span>
                <span>+ 91-8983008534,</span>
                <span>+ 91-8446008660</span>

                <h3>E-Mail</h3>
                <span>info@transtechprojects.com</span>
            </div>}
        </div>
    )
}
