import { createSlice } from "@reduxjs/toolkit";


const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        dark_mode: false
    },
    reducers: {
        modes: (state, actions) => {
            state.dark_mode = actions.payload
        },
    }
})


export const { modes } = themeSlice.actions;
export default themeSlice.reducer;