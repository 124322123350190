import React from 'react';
import './Column.product.scss';
import IMG from '../../../img/column/Column_Internals.png';
import { Link } from 'react-router-dom';

export default function ColumnProduct() {
  return (
    <div className="ColumnProduct">
      <div className="column-product-container">
        <div className="left">
          <h2>Column Internals</h2>
          <span>The performance of packed columns that use modern high efficiency packing is closely related to the performance of the column internals such as liquid distributors, collectors, support plates & mist eliminators</span>
          <span>TransTech offers a series of column internals to be used with random and structured packing. These internals are specifically designed and selected to maximise the performance of the packing and can be effectively used in various combinations.</span>
          <span>Column internals are available in metal, thermoplastic & teflon for applications in corrosive, hot and pressurised systems found in chemical, hydrocarbon processing and gas-liquid absorption units.</span>
          <span>TransTech also supply various random packing suitable for your application. Backed up by experienced chemical engineers, we provide total solutions for new systems or modification of existing systems for efficient operation.</span>
        </div>
        <div className="right">
          <Link to="/showcase/showcolumninternals">
            <span>SHOWCASE</span>
          </Link>
          <img src={IMG} alt="" />
        </div>
      </div>
    </div>
  )
}
