import React from 'react'
import './History.scss'
import p1 from '../../../img/aboutgallery/o1.JPG'
import p2 from '../../../img/aboutgallery/o2.JPG'
import p3 from '../../../img/aboutgallery/o3.JPG'
import p4 from '../../../img/aboutgallery/o4.JPG'
import { Link } from 'react-router-dom'

export default function History() {

  const data = [
    {
      id: 1,
      img: p1
    },
    {
      id: 2,
      img: p2
    },
    {
      id: 3,
      img: p3
    },
    {
      id: 4,
      img: p4
    },
  ]
  return (
    <div className="History">
      <div className="history-container">
        <div className="history-content">
          <h2>About Company History</h2>

          <ul>
            <li>1996 - Aniruddha & Vivek started business activities. Both worked with Tata Honeywell Ltd. (now, Honeywell Automation India Ltd.)</li>
            <li>1999 - Trans Tech Projects Pvt. Ltd. is registered with aim of providing Automation & Engineering Services globally.</li>
            <li>2000 - Started relationship with 1st US based client and still working for the same client on various projects.</li>
            <li>2001 / 2006 / 2010 - Received ISO 9001:2008 certification & re-certification.</li>
            <li>2017 - Received ISO 9001:2015 certification.</li>
            <li>Gradual growth over 100 resources in past 16 years.</li>
            <li>Served more than 100 clients in USA, Canada and all over the world with repeat clientele.</li>
          </ul>
        </div>
        <div className="history-img">
          <Link to="/showcase">
            <span>SHOWCASE</span>
          </Link>
          {data.map((item) => {
            return <img src={item.img} key={item.id} alt="" />
          })}
        </div>
      </div>
    </div>
  )
}
