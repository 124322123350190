import React from 'react'
import './Plant.scss'

export default function Plant() {
  return (
    <div className="Plant">
        <h2>Chemical Manufacture Plant</h2>
        <div className="Plant-container">
            <span>Local Chemical Manufacturing Company was looking for engineering help for relocation of existing plant to another existing plant and addition of new plant for new products.</span>
            <span>We have provided detail engineering services including 3D Modeling of the plant, Utility Design, Electrical & Instrumentation Engineering, Civil & Structural Design, Procurement Support, Site supervision support.</span>
            <span>The project consists of 6 plants including modifications in existing utilities / pipe rack. It was executed in steps over a period of 1 year.</span>
        </div>
    </div>
  )
}
