
import './App.css';
import Automation from './components/Services/Auto/Automation';
import DetailEng from './components/Services/DetailEng/DetailEng';
import Modelling from './components/Services/Model/Modelling';
import Modular from './components/Services/Modular/Modular';
import Software from './components/Services/Software/Software';
import Auto from './components/Showcase/Auto/Auto';
import Column from './components/Showcase/Column/Column';
import Custom from './components/Showcase/Custom/Custom';
import DetailEn from './components/Showcase/DetailEng/DetailEn';
import ShowModeling from './components/Showcase/Model/ShowModeling';
import Scrubbing from './components/Showcase/Scrubbing/Scrubbing';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Showcase from './pages/Showcase/Showcase';
import ScrollToTop from './ScrollToTop';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from './pages/About/About';
import History from './components/About/History/History';
import SoftTools from './components/About/SoftTools/SoftTools';
import Quality from './components/About/Quality/Quality';
import Products from './pages/Products/Products';
import ScrubbingProduct from './components/Products/Scrubbing/ScrubbingProduct';
import ColumnProduct from './components/Products/Column/Column.product';
import Controlpanel from './components/Products/Control/Controlpanel';
import Customers from './pages/Customers/Customers';
import Equipment from './components/Customers/Equipment/Equipment';
import SystemIntegrators from './components/Customers/SystemIntegrators/SystemIntegrators';
import Consultilng from './components/Customers/Consulting/Consultilng';
import IT from './components/Customers/IT/IT';
import Offshore from './pages/Offshore/Offshore';
import Careers from './pages/Careers/Careers';
import Contact from './pages/Contact/Contact';
import Success from './pages/Success/Success';
import ControlInt from './components/Success/Control/ControlInt';
import ModularSkid from './components/Success/ModularSkid/ModularSkid';
import EngFirm from './components/Success/EngFirm/EngFirm';
import Plant from './components/Success/Plant/Plant';
// import ScrubbingSys from './components/Success/Scrubbing/ScrubbingSys';
import { useSelector } from 'react-redux';
import Testimonial from './components/Testimonial/Testimonial';
import Profile from './components/Profile/Profile';


function App() {
  const show = useSelector(state => state.nav.show)
  const darkmode = useSelector(state => state.theme.dark_mode);

  return (
    <div style={darkmode ? { background: '#1c1e23' } : {}} className={`${show && 'show'} ${darkmode ? 'theme-dark' : 'theme-light'} App`}>
      <Router>
        <ScrollToTop />
       
        <Routes>
          <Route exact path="/" element={< Home />} />
          <Route exact path='/about' element={<About />} >
            <Route exact path='' element={<History />} />
            <Route exact path='softwaretools' element={<SoftTools />} />
            <Route exact path='quality' element={<Quality />} />
          </Route>
          <Route exact path="/services" element={< Services />}>
            <Route exact path='' element={<Automation />} />
            <Route exact path='detailengineering' element={<DetailEng />} />
            <Route exact path='modular' element={<Modular />} />
            <Route exact path='software' element={<Software />} />
            <Route exact path='modeling' element={<Modelling />} />
            <Route exact path='autoprofile' element={<Profile />} />
            <Route exact path='engprofile' element={<Profile />} />
            <Route exact path='profile3d' element={<Profile />} />
          </Route>
          <Route exact path='/products' element={<Products />} >
            <Route exact path='' element={<ScrubbingProduct />} />
            <Route exact path='columninternals' element={< ColumnProduct />} />
            <Route exact path='controlpanels' element={< Controlpanel />} />
            <Route exact path='scrubbingprofile' element={< Profile />} />
            <Route exact path='columnprofile' element={< Profile />} />
            <Route exact path='controlpanelprofile' element={< Profile />} />

          </Route>
          <Route exact path='/customers' element={<Customers />} >
            <Route exact path='' element={<Equipment />} />
            <Route exact path='systemintegrators' element={<SystemIntegrators />} />
            <Route exact path='consultingcompanies' element={<Consultilng />} />
            <Route exact path='itfirms' element={<IT />} />

          </Route>
          <Route exact path="/showcase" element={<Showcase />} >
            <Route exact path='' element={< Auto />} />
            <Route exact path='showmodeling' element={< ShowModeling />} />
            <Route exact path='showdetailengineering' element={< DetailEn />} />
            <Route exact path='showcoustomdesign' element={< Custom />} />
            <Route exact path='showscrubbingsystem' element={< Scrubbing />} />
            <Route exact path='showcolumninternals' element={< Column />} />
          </Route>
          <Route axact path='/offshore' element={<Offshore />} />
          <Route axact path='/careers' element={<Careers />} />
          <Route axact path='/contact' element={<Contact />} />
          <Route exact path='/success' element={<Success />} >
            <Route exact path='' element={< ControlInt />} />
            <Route exact path='modularskid' element={< ModularSkid />} />
            <Route exact path='engineeringfirm' element={< EngFirm />} />
            <Route exact path='manufacturingplant' element={< Plant />} />
            <Route exact path='scrubbingsystem' element={< ScrubbingProduct />} />

          </Route>
          <Route exact path='/testimonial' element={< Testimonial />} />
        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
