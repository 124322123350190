import React from 'react';
import './Success.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import Banner from '../../components/Banner/Banner';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

export default function Success() {
  return (
    <div className="Success">
       <Navbar />
      <Banner />
      <div className="Success-container">

        <div className="success-left">
          <Sidebar />
        </div>
        <div className="success-right">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
