import React from 'react'
import './Showcase.scss'


import Sidebar from '../../components/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function Showcase() {

  return (
    <div className="Showcase">
       <Navbar />
      <Banner />
      <div className="Showcase-container">
        <div className="left">
          <Sidebar />

        </div>
        <div className="right">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
