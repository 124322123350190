import React, { useEffect, useState } from 'react'
import './Profile.scss'
import { useLocation } from 'react-router-dom';

export default function Profile() {
    const [profile_content, setProfile_content] = useState([]);
    const location = useLocation();
    const profilecontent = [
        {

            h2: 'Instrumentation & Electrical',
            list: [
                {
                    li: "Detail Engineering of Lube Oil Re-refining Plant for Project at Puerto Rico",
                    span: "Client: Chemical Engineering Partners Inc., USA"
                },
                {
                    li: "Detail Engineering of Lube Oil Re-refining Plant at Israel",
                    span: "Client: Chemical Engineering Partners Inc., USA"
                },
                {
                    li: "Detail Engineering of Solvent Recovery System consisting of Distillation Columns & Thin Film Evaporator for a project at Malaysia",
                    span: "Client: Techno Force Ltd., Nashik, India"
                },
                {
                    li: "Detail Engineering of Solvent Recovery System consisting of Distillation Columns",
                    span: "Client: Kimia Zue Huat, Penang, Malaysia"
                },
                {
                    li: "Detail Engineering of NSD Bar Project for plants at Himachal Pradesh & Bhuj.",
                    span: "Client: Hindustan Lever Ltd., Pune"
                },

            ]
        },
        {
            h2: "Process & Mechanical",
            list: [
                {
                    li: "Detail Engineering of Cooling Tower Skid",
                    span: "Client: Chemical Engineering Partners Inc., USA"
                },
                {
                    li: "Extended basic engineering package for a multi-purpose batch plant",
                    span: "Client: Alkyl Amines Ltd., Pune"
                },
                {
                    li: "Extended basic engineering package for a hydrogen recovery plant",
                    span: "Client: Alkyl Amines Ltd., Pune"
                },
                {
                    li: "Extended basic engineering package for a Ammonia recovery & Scrubbing System",
                    span: "Client: Alkyl Amines Ltd., Pune"
                },
                {
                    li: "Detail Engineering for a Cobalt Salts manufacturing plant",
                    span: "Client: Conic Metals Ltd., Mumbai"
                },
                {
                    li: "Assistance in Detail Engineering",
                    span: "Client: Centuri Enka Ltd., Pune"
                },
                {
                    li: "Design of Heat exchangers for Fatty acid plant & solvent recovery system",
                    span: "Client: Hindustan Lever Ltd., Pune"
                }
            ]
        },
        {
            h2: 'Factory Automation',
            list: [
                {
                    li: 'Development of concept, instrumentation, PLC / HMI programming, for a drum cleaning, washing & handling system.',
                    span: 'Customer: US Based System Integrator',
                    span2: 'PLC : AB Control Logix, HMI: Panelview 1000'
                }
            ]
        }
    ];

    const autoProfile = [
        {
            h2: 'Waste Water Treatment Plants',
            list: [
                {
                    li: 'Preparation of wiring diagrams, cabinet layouts & control schematics, and development of Ladder Logic as per customer specifications. The SCADA system was iFIX and was developed by the client.',
                    span: 'PLC : Modicon Quantum SCADA : iFIX'
                },
                {
                    li: "Development of SCADA screens from P & ID's and pictures of existing mimic panels, and implementation of Ladder Logic as per customer specifications.",
                    span: 'PLC : TI505 SCADA : Wonderware Intouch 7.1'
                },
                {
                    li: 'Preparation of wiring drawings, implementation of Ladder Logic, additions in to existing SCADA and new Operator Interface Terminal.',
                    span: 'PLC : AB Control Logix with Remote I/OSCADA : RS View OIT : Panel View 1000+'
                },
                {
                    li: 'Implementation of ladder logic, SCADA development, & CAD Drawings including Panel Layouts, I/O Wiring, Loop Drawings.',
                    span: 'PLC : Automation Direct 05 & 06 series SCADA : iFIX 3.5'
                },
                {
                    li: 'Implementation of ladder logic, SCADA development, & CAD Drawings including Panel Layouts, I/O Wiring, Loop Drawings.',
                    span: 'PLC : MicroLogix 1500 (3 Nos) SCADA : RS-View OIT :Panel View 300 (2 Nos)'
                },
                {
                    li: 'Implementation of ladder logic, SCADA development, OIT development, & CAD Drawings including Panel Layouts, I/O Wiring.',
                    span: 'PLC : SLC 5/05, MicroLogix 1500 SCADA : RS-View OIT : Panel View 1000'
                },
                {
                    li: 'Configuration of SCADA system from P&I Diagrams & I/O List.',
                    span: 'SCADA : Citect 5.42'
                },
            ]
        }, {
            h2: 'Water Treatment / Desalination Plants',
            list: [
                {
                    li: "Preparation of Logic Diagrams from the Functional Specifications and P & ID's. Implementation of Ladder Logic. The SCADA was developed by the client.",
                    span: 'PLC : AB Control Logix (3 Nos) SCADA : Intouch 7.1'
                },
                {
                    li: 'Implementation of Ladder Logic, SCADA, and CAD electrical drawings.',
                    span: 'PLC : AB Control Logix (5 Nos) SCADA : Intouch 8'
                },
                {
                    li: 'Implementation of Ladder Logic, SCADA, and CAD electrical drawings.',
                    span: 'PLC : AB Control Logix (2 Nos) SCADA : FIX32'
                },
                {
                    li: 'Preparation of wiring diagrams, cabinet layouts, and control schematics, development of Ladder Logic, and SCADA',
                    span: 'PLC : PLC 5, SCADA : iFIX'
                },
            ]
        },
        {
            h2: 'Factory Automation',
            list: [
                {
                    li: "Development of concept, instrumentation, PLC / HMI programming, for a drum cleaning, washing & handling system.",
                    span: "Customer: US Based System Integrator",
                    span2: 'PLC : AB Control Logix, HMI: Panelview 1000'
                },
                {
                    li: "Development of concept, instrumentation, PLC programming, PC based data logging software for testing Petrol Pumps. The project involved interfacing with Petrol Pump software on Modbus. It also involved networking of the several test stations on CAN bus. Customer : L & T, Powai.",
                    span: "PLC : B&R PP41 (18 Nos), CP476, CP430, PP120 SCADA : Customized Data Logging software developed in VB."
                },
                {
                    li: "Development of logic in Automation Basic for a Packaging Machine. The SCADA was developed by the client.",
                    span: "PLC : B&R System CP260, SCADA : Intouch 7.1"
                },
                {
                    li: "Development of logic for conveying system used for cracker packing. The SCADA was developed by the client.",
                    span: "PLC : AB Compact Logix with VFD’s on DeviceNet SCADA : Intouch 9"
                },
                {
                    li: "Implementation of drawings like network drawings, I/O wiring drawings, power wiring drawings for conveying systems used in Auto industry.",
                    span: "PLC : Siemens"
                },
            ]
        },
        {
            h2: "Chemical & Pharmaceutical Plants",
            list: [
                {
                    li: "Design of Control Panel & MCC, Control Logic & SCADA Development for Solvent Recovery Plant consisting of Distillation, Thin Film Evaporation, Liquid Liquid Extraction Units. Customer : Technoforce Solutions Ltd., Nashik",
                    span: "PLC : GE Fanuc 90-30 SCADA : Cimplicity"

                },
                {
                    li: "Design of Control Panel, Control Logic & HMI Development for a Solvent Recovery unit consisting of Distillation.",
                    span: "Customer : US Based System Integrator",
                    span2: "PLC & HMI: Automation Direct"
                },
                {
                    li: "Design of Control Panel, Control Logic & HMI Development for a Distillation of Methanol & Water.",
                    span: "Customer : US Based System Integrator",
                    span2: "PLC: AB SLC 5/05 HMI: Automation Direct"
                },
                {
                    li: "Preparation of wiring drawings, implementation of Ladder Logic & HMI for Adsorption Unit for Ethanol Purification.",
                    span: "Customer : Spectrum Ethers Ltd., Nashik",
                    span2: "PLC : AB Micrologix"
                },
                {
                    li: `Preparation of wiring drawings, implementation of Ladder Logic, additions in to existing SCADA for Hydrogen Pressure
                    Swing Adsorption Unit. Customer : Alkyl Amines, Kurkumbh.`,
                    span: "PLC : GE Fanuc 90-30 SCADA : Wonderware 9"
                },
                {
                    li: "Implementation of safeties and interlocks for a plant manufacturing drug intermediates.",
                    span: "PLC : AB Micrologix 1500 (2 Nos) OIT : AB Panel View 1000 (2 Nos)"
                },
                {
                    li: `Preparation of wiring drawings, implementation of Ladder Logic, for Air Pre-purification Unit.
                    Customer : Dow Agro-Sciences, Chiplun.`,
                    span: "PLC : SLC 5/02"
                },
                {
                    li: "Development of SCADA screens from P & ID's & configuration of MTL 8000 I/Os.",
                    span: "SCADA : Citect"
                },
                {
                    li: "Preparation of wiring drawings, implementation of Ladder Logic, for Detergent plant.",
                    span: "Customer : Vashisti Detergents, Chiplun.",
                    span2: 'PLC : SLC 5/03 OIT : AB Panel View 1400, 1000'
                },
            ]
        },
        {
            h2: "Liquid Handling and Pipe Line Projects",
            list: [
                {
                    li: "Implementation of ladder logic and SCADA for salt pipe line.",
                    span: "PLC : AB Micrologix 1500 (5 Nos) SCADA : iFIX 3.5"
                },
                {
                    li: "Implementation of ladder logic and SCADA for Oil Tank Farm Control system.",
                    span: "PLC : AB Control Logix SCADA : iFIX 3.5"
                },
                {
                    li: "Implementation of ladder logic and SCADA for Oil Tank Farm Control system.",
                    span: "PLC : B & R CP476 SCADA : iFIX 3.0"
                }
            ]
        },
        {
            h2: "Pump Stations",
            list: [
                {
                    li: "Preparation of Panel Layouts, Control diagrams, wiring drawings based on specifications & BOM. Configuration of Screens in OIT and Logic implementation using ladder or function blocks.",
                    span: `PLC : MicroLogix / Momentum / Quantum OIT : Magelis / Advantech / PanelView
                    (Approx. 25 Projects)`
                },
            ]
        },
        {
            h2: "Miscellaneous",
            list: [
                {
                    li: "Preparation of wiring drawings, implementation of Ladder Logic, HMI development for Drum Washing Unit.",
                    span: "PLC & HMI : Automation Direct"
                },
                {
                    li: "Modification in PLC program for a Gas Plant to incorporate logic changes.",
                    span: "PLC : Automation Direct 405"
                },
                {
                    li: "Implementation of Ladder logic for Drink Dispensing Machine",
                    span: "PLC : Trilogi"
                },
                {
                    li: "Control of Humidity, CO2, and Temperature in a Green House.",
                    span: "PLC : AB Micrologix 1500 SCADA : RS View OIT : Panel View 300"
                }
            ]
        },

    ];

    const d3Profile = [
        {
            list: [
                {
                    span: "The Page is Under Construction."
                }
            ]
        }
    ];



    const products_scrubbing_system = [
        {

            list: [

                {
                    li: "Venturi Scrubber for removal of Ammonia vapors generated from a drum filling system",
                    span: "Client : Alkyl Amines Ltd, Pune"
                },
                {
                    li: "Scrubbing System for a caprolcatum plant",
                    span: "Client : Centuri Enka Ltd., Pune"
                },
                {
                    li: "SO2 Absorption System to produce H2SO3 Solution",
                    span: "Client : Riddhi Siddhi Gluco Biols Ltd., Gokak"
                },
                {
                    li: "Wet Gas Scrubber for PSM Plant",
                    span: "Client : Vashishti Detergent Ltd., Chiplun"
                },
                {
                    li: "HCl & SO2 Scrubbing Systems (4 Nos)",
                    span: "Client : Shri Nicoset Chemicals Ltd., Ankaleshwar"
                },
                {
                    li: "HCl Scrubber for HCl Fumes generated from Reactor",
                    span: "Client : Hindustan Lever Ltd., Taloja"
                },
                {
                    li: "HCl Scrubber in PP/FRP (Design Pressure: 2.5 b qar g) for HCl Fumes",
                    span: "Client : Gujrat Flurochemicals Ltd., Baroda"
                },
                {
                    li: "SO2 Scrubber for Sulphonation Plant",
                    span: "Client : Vashishti Detergent Ltd., Chiplun"
                },
                {
                    li: "Scrubber for P2S5 Dust & H2S Gas",
                    span: "Client : Cynamide Agro Ltd., Valsad"
                },
                {
                    li: "Moisture Removal System consisting of Cyclone & Blowers",
                    span: "Client : Hindustan Lever Ltd., Khamgaon, Orai, Pondichery, Sumerpur, Himachal, Silvassa"
                },
                {
                    li: "Fume Extraction System for Welding Electrode Pickling Unit",
                    span: "Client : Advani OrelikonLtd., Ahamadnagar"
                },
                {
                    li: "HCl Absorption System",
                    span: "Client : Industrial Perfumes Ltd., Chiplun"
                },
                {
                    li: "HCl and SO2 Absorption System",
                    span: "Client : Searle India Ltd., Panoli",
                    span2: "Consultant : Kvaerner Powergas India Ltd."
                },
                {
                    li: "Emergency Chlorine Absorption System",
                    span: "Client : Gwalior Chemicals Ltd., Nagada",
                    span2: "Consultant : Kvaerner Powergas India Ltd."
                },
                {
                    li: "Centrifugal Fan in PP / FRP Construction",
                    span: "Client : Agrimore Ltd., Atul, Gujrat"
                }
            ]
        }
    ]

    const products_column_internals = [
        {
            list: [

                {
                    li: "Design and Manufacturing of Feed Diffuser, Liquid Distributor Tray in Stainless Steel for Catalytic Reactors",
                    span: "Client: Lloyds Steel Ltd., Mumbai"
                },
                {
                    li: "Design and Manufacturing of Column Internals in PTFE",
                    span: "Client: Aquapharm Chemical Co. Ltd., Mahad",
                    span2: "Consultant: Kaverener Power Gas Ltd., Pune"
                },
                {
                    li: "Design and Manufacturing of Orifice Pan Type Distributor, Gas Injection Support Plate & Bed Limiter in Polypropylene",
                    span: "Client: Gujrat Flurochemicals Ltd., Baroda"
                },
                {
                    li: "Design and Manufacturing of Distributors, Support Grids in Carbon Steel",
                    span: "Client: Dorf Ketal Ltd., Mumbai"
                },
                {
                    li: "Design and Manufacturing of Pipe Distributors & Gas Injection Support Plates in SS 304",
                    span: "Client: Max India Ltd., Mysore"
                },
                {
                    li: "Design and Manufacturing of Column Internals for NOx Scrubber in Stainless Steel",
                    span: "Client: ICI India Ltd., Valsad"
                },
                {
                    li: "Design and Manufacturing of Distributors, Support Grids in Polypropylene",
                    span: "Client: Nirmal Colorants Ltd., Roha"
                },
                {
                    li: "Design and Manufacturing of Column Internals for HF Absorber and Distillation Columns in PTFE",
                    span: "Client: Gujrat Flurochemicals Ltd., Baroda"
                },
                {
                    li: "Design and Manufacturing of Bubble Cap Trays for sulphuric acid scrubber in Statinless Steel",
                    span: "Client: Gujarat State Fertilizers & Chemicals Ltd., Baroda"
                },
                {
                    li: "Design and Supply of Column Internals of diameter 1m for MEA Stripping Column in Stainless Steel",
                    span: "Client: HPCL, Mumbai"
                },
            ]
        }
    ]

    useEffect(() => {
        if (location.pathname === '/services/autoprofile') {
            setProfile_content(autoProfile)
        }
        else if (location.pathname === '/services/engprofile') {
            setProfile_content(profilecontent)
        }
        else if (location.pathname === '/services/profile3d') {
            setProfile_content(d3Profile)
        }
        else if (location.pathname === '/products/scrubbingprofile') {
            setProfile_content(products_scrubbing_system)
        }
        else if (location.pathname === '/products/columnprofile') {
            setProfile_content(products_column_internals)
        }
        else if (location.pathname === '/products/controlpanelprofile') {
            setProfile_content(autoProfile)
        }

        // eslint-disable-next-line
    }, [location.pathname])
    return (
        <div className="Profile">
            <h2>Experience Profile</h2>
            {profile_content && profile_content.map((item, i) => {
                return <div className="right-content" key={i}>
                    {item.h2 && <h3>{item.h2}</h3>}
                    <ul>
                        {item.list.map((item, i) => {
                            return (
                                <div className="ul-inside-con" key={i}>
                                    {item.li && <li>{item.li}</li>}
                                    {item.span && <span>{item.span}</span>}
                                    {item.span2 && <span>{item.span2}</span>}
                                </div>
                            )
                        })}
                    </ul>
                </div>


            })}
        </div>
    )
}
