import React from 'react'
import './Careers.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Link } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function Careers() {
    return (
        <div className="Careers">
             <Navbar />
            <Banner />
            <div className="careers-container">
                <div className="careers-left">
                    <Sidebar />
                </div>
                <div className="careers-right">
                    <h2>Careers</h2>
                    <div className="careers-right-container">
                        {/* <span>Trans Tech Projects is an organization that values contribution, provides opportunities, recognizes achievements and rewards results.</span>
                        <span>At Trans Tech Projects, you get to see company’s vision, and how you can play a key role towards meeting them practicing company’s values. You get to discuss growth areas and inputs on a regular basis with your seniors. This ensures you keep learning and growing. Your responsibilities are a mix and match of your current skill sets and roles, and some responsibilities for the future role that you are likely to play. This ensures that you are always a step ahead on your career. You get used to being empowered with the faith that you can do it.</span> */}
                        <span>Trans Tech Projects is always looking for Managers, Engineers, Designers for various disciplines as below:</span>

                        <table className="careers-table">
                            <thead>
                                <tr>
                                    <th>Automation</th>
                                    <th>Engineering</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span>Project Manager:</span>
                                        10+ years experience in PLC / SCADA Project execution, client co-ordination. Good Communication Skills.
                                    </td>
                                    <td>
                                        <span>Project Manager:</span>
                                        10+ years experience in Detail Engineering of Chemical Plants, Skids, Utilities. Should be proficient in Autocad and 3D modeling software packages.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>PLC / SCADA Programmer:</span>
                                        3+ Years experience in PLC / SCADA programming on leading platforms, Panel Design, Commissioning. Should be able to co-ordinate with clients. Should posses passport and ready to travel abroad.
                                    </td>
                                    <td>
                                        <span>Project Engineer (Chem / Mech):</span>
                                        5+ years experience in Detail Engineering of Chemical Plants, Skids, Utilities. Should be proficient in Autocad and 3D modeling software packages. Should posses passport and ready to travel abroad.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Project Engineer (Instru / Elec) :</span>
                                        7+ years experience in PLC Panel Design, Electrical Load Calculations, instrument selection, drawings checking, testing and commissioning. Should be proficient in Autocad. Should posses passport and ready to travel abroad.
                                    </td>
                                    <td>
                                        <span>Lead Designer:</span>
                                        7+ years experience in 3D Modeling of Piping related projects using Autocad / Cadworx / Inventor / Solidworks / SP3D. Should posses passport and ready to travel abroad.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Designer :</span>
                                        3+ years experience in Autocad / Autocad Electrical for PLC Panel Design. Testing experience will be an added advantage. Should posses passport and ready to travel abroad.
                                    </td>
                                    <td>
                                        <span>Designer :</span>
                                        3+ years experience in 3D Modeling of Piping related projects using Autocad / Cadworx / Inventor / Solidworks / SP3D. Should posses passport and ready to travel abroad.
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <span className="bold-span">Wish to join TransTech Projects?</span>
                        <span>Email your CV to <Link to='' className="span-link">hr@transtechprojects.com</Link></span>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
