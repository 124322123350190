import React from 'react'
import './Controlpanel.scss'
import p1 from '../../../img/control_panel_gallery/contral-panal-01.jpg'
import p2 from '../../../img/control_panel_gallery/contral-panal-02.jpg'
import { Link } from 'react-router-dom'

export default function Controlpanel() {
  return (
    <div className="Controlpanel">
        <div className="control-panels-container">
            <div className="left">
        <h2>Control Panels</h2>
                <span>Trans Tech has qualified electrical and control engineers having several years of experience in the design and development of electrical control panels.</span>
                
                <h3>A typical project design would involve the development of</h3>
                <ul>
                    <li>Fully Detailed Panel Layouts & general assembly</li>
                    <li>Wiring Schematics</li>
                    <li>Bill of Materials</li>
                    <li>Full Safety Schematics</li>
                    <li>PLC I/O Schematics</li>
                    <li>Termination & I/O Schedules</li>
                </ul>
                
                <h3>Motor Control Center</h3>

                <span>Trans Tech offers design and development of Motor Control Centers in integration with Control Automation System. The electrical design are based on Fully Type Tested Assemblies upto Form IV Type 7. Going one step ahead the intelligent / SMART function allow Single plant control & data gathering at the plant central system.</span>
                <span>The switch gear products / components used are high quality multi-vendor products. The MCC is pre tested and pre configured at the factory as per relevant international standards</span>
            </div>
            <div className="right">

            <Link to="/showcase">
            <span>SHOWCASE</span>
          </Link>
                <img src={p1} alt="" />
                <img src={p2} alt="" />
            </div>
        </div>
    </div>
  )
}
