import React from 'react'
import './ModularSkid.scss'

export default function ModularSkid() {
  return (
    <div className="ModularSkid">
        <h2>Modular Skid Manufacturer</h2>
        <div className="ModularSkid-container">
            <span>A global Modular Skid OEM approached us for providing support on their projects.</span>
            <span>We have now been working with the same client for past many years as their own team.</span>
            <span>We have been providing following services</span>
            <ul>
                <li>3D Modeling of Skids</li>
                <li>Piping Spool Drawings</li>
                <li>Support and Structural Frame Drawings</li>
                <li>Instrument Datasheets</li>
                <li>Instrument Location Drawings</li>
                <li>PLC / MCC Panel Design with Wiring Drawings</li>
                <li>PLC & HMI Programming</li>
                <li>Operation & Maintenance Manual</li>
            </ul>
            <span>For few occasions our programmers visited sites in Africa, Asia Pacific Region for start-up assistance.</span>
            <span>Our cost effective services have helped them to become competitive in terms of costs & turnaround.</span>
        </div>
    </div>
  )
}
