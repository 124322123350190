import React from 'react';
import './Offshore.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import offshoreIMG from '../../img/offshore/offshoreInfographic2.jpg';
import Banner from '../../components/Banner/Banner';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

export default function Offshore() {
    return (
        <div className="offshore">
            <Navbar />
            <Banner />
            <div className="offshore-main-container">
                <div className="offshore-left">
                    <Sidebar />
                </div>
                <div className="offshore-right">
                    <div className="right-content">
                        <div className="right-home-container">
                            <h2>Offshore Model</h2>
                            <div className="offshore-container">
                                <span>We deliver Automation, Engineering & IT solutions to our clients around the world following the successful offshore model.</span>
                                <img className="offshore-img" src={offshoreIMG} alt="" />
                                <h3>The advantages of partnering with Trans Tech are</h3>
                                <ul>
                                    <li><span>Communication :</span> Both the promoters have been educated & lived in USA & aware about the western business culture. Most of the staff is educated in English medium & has excellent written as well as spoken English skills. We have a devised a specific way to manage the project in a day to day interaction using email / FTP.</li>
                                    <li><span>Quality & Delivery :</span> The management & staff has expertise in necessary domain & committed for quality & delivery to meet project deadlines. For US based clients, we use the time difference advantage as we are ahead of them by about 11 hours. So we work while they sleep. Client can offload the work before leaving for the day & get the ready job on next day morning!</li>
                                    <li><span>Training :</span> The staff is always trained to keep update about the latest changes in various domains.</li>
                                    <li><span>Confidentiality :</span> We sign a Non Disclosure Agreement & assure complete confidentiality with respect to all transactions.
                                    </li>
                                    <li><span>Flexibility :</span> During peak load & non availability of the expertise in various domains, outsourcing is the best option.</li>
                                    <li><span>Focus on Core Areas </span> Client can focus on core competencies while outsourcing the non core activities like drafting, documentation.</li>
                                    <li><span>Cost Benefits :</span> Last but not the least, we offer these services to at most competitive rates, as compared to prevailing rates in North America & Europe. We charge based on man hour or lumpsum based on mutual agreement. Payment can be done by monthly or based on milestone by wire transfer or check.</li>
                                    <li><span>Long Term Relationship :</span> As we go along with the project, both organizations gets familiar with each other's style from all aspects. As we develop the relationship, this helps in reducing implementation time & hence the costs.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="left-home-container">
                            <Link to="/showcase">
                                <span>SHOWCASE</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
