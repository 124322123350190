import React from 'react'
import './IT.scss'
import { Link } from 'react-router-dom'
import ShowcaseComponent from '../../ShowcaseComponent/ShowcaseComponent'

export default function IT() {
  return (
    <div className='IT'>
        <div className="It-container">
          <div className='left'> 
        <h2>IT Firms</h2>
            <span>For software development we have started another company VASP Solutions LLP.</span>
            <span>For more detail of the company please visit it's website <Link target='_blank'  rel="noreferrer" to="https://www.vaspsolutions.com/">vaspsolutions.com</Link></span>
          </div>
          <div className='right'>
            <ShowcaseComponent />
          </div>
        </div>
    </div>
  )
}
