import React from 'react'
import './Consultilng.scss'
import ShowcaseComponent from '../../ShowcaseComponent/ShowcaseComponent'

export default function Consultilng() {
  return (
    <div className="Consulting">
      <div className="Consulting-container">
        <div className='left'>
          <h2>Engineering & Consulting Companies</h2>

          <span>Engineering & consulting companies can avail our support as extended team of their in-house team. This helps to have flexibility in terms of resources with cost effectiveness. This provides great benefit to in—house team to taper off their peak loads by using our services for some of their non-core activities.</span>

          <h3>How can we help?</h3>
          <ul>
            <li>Assistance on P&IDs – Cadworx, Autocad</li>
            <li>3D Modeling of plant sections</li>
            <li>Piping Isometric Drawings from 3D Model</li>
            <li>2D Layout Drawings from 3D Models</li>
            <li>Assistance in project documentation</li>
            <li>As-build Drawings</li>
          </ul>
        </div>
      <div className='right'>
        <ShowcaseComponent />
      </div>
      </div>
      
    </div>
  )
}
