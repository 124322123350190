import React from 'react'
import './EngFirm.scss'

export default function EngFirm() {
  return (
    <div className="EngFirm">
        <h2>Engineering Firm</h2>
        <div className="EngFirm-container">
            <h3>P&ID Revamp Project:</h3>
            <span>An engineering firm approached us for providing support on large project of P&ID revamp project using Cadworx P&ID. We have provided support with team of 4 and ramped upto 12 resources for peak load. The project consists of many units. Each unit has different steps of drafting based on old P&IDs as per new standards, Field walkdown checking and final updation of P&IDs with database checking.</span>
            <span>The project duration was 1 & ½ years and successfully executed by our team as an extended team of engineering firm.</span>
            <span>Our services have provided flexibility in terms of resources and cost effectiveness.</span>

            <h3>As build drawings:</h3>
            <span>An engineering firm approached us for providing drafting support on large project of updating As-build drawings for all disciplines – P&IDs, Piping, Electrical & Instrumentation, Civil & Structural.</span>
            <span>The project duration was about 6 months and successfully executed by our team as an extended team of engineering firm.</span>
            <span>Our services made our client and end user happy to execute the project within tight budget</span>
        </div>
    </div>
  )
}
