import React from 'react'
import './About.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

export default function About() {
  return (
    <div className="About">
       <Navbar />
      <Banner />
      <div className="About-container">
        <div className="left">
          <Sidebar />
        </div>
        <div className="right">
          <Outlet />
        </div>
      </div>
      <Footer/>
    </div>
  )
}
