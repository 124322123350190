import React from 'react'
import './ControlInt.scss'

export default function ControlInt() {
  return (
    <div className="ControlInt">
        <h2>Control System Integrator</h2>
        <div className="ControlInt-container">
            <span>Control System Integrator was looking for options for Panel Drawings and PLC / SCADA programming to take care of their peak loads</span>
            <span>We have now been working with this client for past few years as extended team to take care of their additional load when their in-house team gets busy.</span>
            <span>We have been providing following services</span>
            <ul>
                <li>Panel Design</li>
                <li>Wiring Drawings</li>
                <li>Loop Drawings</li>
                <li>PDF Submittals</li>
                <li>PLC / SCADA Programming</li>
            </ul>
            <span>Our services provided flexibility to client in terms of resources to manage their peak workload. Their in-house team is happy as they get support as and when required even at short notice.</span>
        </div>
    </div>
  )
}
